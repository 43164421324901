



























import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, toCamelCase, toSnakeCase, showError } from 'utils'
import { Customer, Body, BottomSheetButton, CustomerGroup } from 'typings'
import { CustomerDialog, MasterItemComponent } from 'components'

interface SetUp {
  customers: Ref<Array<Customer>>
  customerGroups: Ref<Array<CustomerGroup>>
  showCustomerDialog: Ref<boolean>
  isAdd: Ref<boolean>
  customerProp: Ref<unknown>
  openCustomerDialog: (customer: Customer) => void
  getCustomers: () => void
  confirmReset: (param: string) => void
  saveSortOrder: (body: Body) => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  bottomSheetListButton: Array<BottomSheetButton>
  onSaveSeriPriority: (params: Customer[]) => void
}

const Customers = defineComponent({
  components: {
    CustomerDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const customers = ref<Array<Customer>>([])
    const customerGroups = ref<Array<CustomerGroup>>([])
    const isSorting = ref(false)
    const customerProp = ref({})
    const showCustomerDialog = ref(false)
    const isAdd = ref(true)
    const { $toast } = root

    const loading = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.customer.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.customer.sort') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('master.customer.seri_priority') as string,
        classes: [],
        icon: 'mdi-priority-high',
        action: 'on-set-seri-priority',
        disabled: false
      },
      {
        id: 4,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getCustomers = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(endpoints.CUSTOMERS)
        // need to convert result from snake_case to camelCase
        customers.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const getCustomerGroups = async () => {
      try {
        const { data } = await api.get(`${endpoints.CUSTOMER_GROUP}`)
        customerGroups.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const onSaveSeriPriority = async (params: Customer[]) => {
      try {
        await api.put(`${endpoints.CUSTOMERS}update_multiple`, toSnakeCase(params))
        $toast.success(root.$t('master.msg.update_successful'))
        await getCustomers()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      }
    }

    const saveSortOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.CUSTOMERS}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getCustomers()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openCustomerDialog = (customer: Customer | null): void => {
      if (customer) {
        customerProp.value = JSON.parse(JSON.stringify(customer))
        isAdd.value = false
      } else {
        // default customer prop
        customerProp.value = {
          name: '',
          yomi: '',
          shortName: '',
          tel: '',
          fax: '',
          email: '',
          invoiceEmail: '',
          code: '',
          zipCode: '',
          address1: '',
          address2: '',
          invoiceMethod: null,
          invoiceEmailDestination: null,
          allowPreSale: true,
          customerGroup: 1, // market
          theirCode: ''
        }
        isAdd.value = true
      }
      showCustomerDialog.value = true
    }

    const confirmReset = async (): Promise<void> => {
      $toast.info(root.$t('master.customer.customer_list_is_reset'))
      await getCustomers()
    }

    onMounted(() => {
      getCustomers()
      getCustomerGroups()
    })

    return {
      customers,
      customerGroups,
      customerProp,
      loading,
      showCustomerDialog,
      isAdd,
      isSorting,
      getCustomers,
      confirmReset,
      openCustomerDialog,
      saveSortOrder,
      bottomSheetListButton,
      onSaveSeriPriority
    }
  }
})

export default Customers
